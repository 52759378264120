.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: right;
  height: 30px;
}
.navHeaderRight {
  height: 45px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-right: 50px;
}
.navbarHeaderRight {
  margin-right: 20px;
}
.signUp {
  margin-top: 10px;
  text-align: center;
}
.signupPage-wrapper {
  margin: 0 auto;
  max-width: 980px;
  margin-top: 20px;
}
.signupPage-wrapper-full .logo-wrapper{
  margin-top: 20px;
}
.signupPage{
  padding: 20px 20px 20px 20px !important;
  background: rgba(255,255,255, 1);
  z-index: 3;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #03d665;
}
/* @media screen and (min-width: 992px) {
  .signupPage-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
} */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #03d665 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: #03d665 !important;
}
.loginBack {
  font-size: 14px;
  margin-bottom: 14px;
  text-align: right;
}
.loginBack a{
  color: #169933 !important;
  font-weight: bold;
}
.loginBack a:hover{
  text-decoration: underline;
}
.__profile{
  background: linear-gradient(to right, #ee5a6f, #f29263);
}
.__orderForm {
  padding: 0px 100px 0px 100px;
}
.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  margin-top: 0px;
}
.billGen {
  padding: 0px 10%;
}
.ant-table-content {
  overflow-y: auto !important;
}
.ant-input-number-input-wrap {
  height: 40px;
}
.ant-col.ant-form-item-control .ant-input-number {
  border: 1px solid #03D665 !important;
}
button.ant-btn.ant-btn-primary {
  height: 40px;
}
input.ispBill:focus {
  border-color: #DD2A95 !important;
}

.isp-form-wrapper {
  height: 100%;
  width: 100%;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  background: url("./assets/images/login_bg.png") !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  position: relative !important;
  background-size: cover !important;
}
.isp-form-wrapper .logo-wrapper {
  z-index: 9;
  text-align: center;
}
.isp-form-wrapper .logo-wrapper .loginLogo {
  width: 200px;
  border-radius: 50%;
  z-index: 9;
  margin: 0 auto;
  margin-bottom: 57px;
}
.isp-form-wrapper .logo-wrapper .page-title {
  font-size: 24px;
  font-weight: bold;
  background: #fff;
  padding: 5px 20px;
  z-index: 11111;
  border-radius: 10px;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  color: #c1692f;
  width: fit-content;
  margin: 0 auto;
  margin-top: -8px;
}
.isp-form-wrapper .isp-form {
  padding: 32px 55px;
  background: #fff;
  z-index: 1;
  text-align: center;
  position: relative;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.isp-form-wrapper .isp-form .page-sub-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #00B218;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 10px;
}
.isp-form-wrapper .isp-form hr {
  margin-top: 15px;
  margin-bottom: 15px;
}
.isp-form-wrapper .isp-form input[type=text] {
  height: 40px;
}
.isp-form-wrapper .isp-form .mt-30 {
  margin-top: 30px;
}
.isp-form-wrapper .isp-form .otp input:focus-visible {
  background-color: transparent !important;
  outline: #00B218 !important;
  /* border: 1px solid #00B218 !important; */
}
.isp-form-wrapper .ant-input-affix-wrapper {
  border-radius: 15px;
}
.isp-form-wrapper .ant-input-affix-wrapper input {
  margin-left: 10px;
}
.isp-form-wrapper .ant-input-affix-wrapper:focus {
  /* border-color: #03d665 !important; */
  box-shadow: none;
}
.isp-form-wrapper .ant-input-affix-wrapper:hover {
  border-color: #03d665 !important;
}
.isp-form-wrapper .signupPage-wrapper .ant-col.ant-form-item-control .ant-input {
  border-color: #03d665 !important;
}
.isp-form-wrapper .ant-alert {
  position: fixed;
  right: 20px;
  top: 0;
}

@media screen and (min-width: 992px) {
  .isp-form-wrapper .container {
    width: 768px;
    max-width: 768px;
  }
}



table.GeneratedTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #fff;
  border-style: solid;
  color: #000000;
  
}

table.GeneratedTable td, table.GeneratedTable th {
  border-width: 2px;
  border-color: #fff;
  border-style: solid;
  padding: 3px;

}

table.GeneratedTable thead {
  background-color: #fff;
}

#customClientIdx {
  border: 0px solid #03D665 !important;
}
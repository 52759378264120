.orgchart.myChart {
    background-image: linear-gradient(
        90deg,
        rgba(33, 90, 136, 0.15) 10%,
        rgba(0, 0, 0, 0) 10%
      ),
      linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
  }
  
  .orgchart.myChart > ul > li > ul li::before {
    border-top-color: #215a88;
  }
  
  .orgchart.myChart > ul > li > ul li .oc-node::before,
  .orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #215a88;
  }
  
  .orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: #215a88;
    color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
  }
  
  .orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    color: #215a88;
    background-color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
    border: 1px solid #215a88;
  }

  .orgchart-container {
    box-sizing: border-box;
    position: relative;
    margin: 10px;
    height: 200px;
    background-color: #fff;
    border: 2px dashed #aaa;
    border-radius: 5px;
    overflow: auto;
    text-align: center;
}
  
.orgchart.myChart {
    margin-top: 20px;
}
.card-block {
    padding: 1.25rem;
}
.text-white {
    color: #fff!important;
    text-align: center;
}
.m-b-25 {
    margin-bottom: 25px;
}
.f-w-600 {
    font-weight: 600;
    color: #fff!important;
}
.f-w-601 {
    font-weight: 600;
    color: #000!important;
}